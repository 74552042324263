const activeSubItem = document.getElementsByClassName('sub-active');
const mobileNavToggle = document.getElementById('mainNavToggle');

mobileNavToggle.addEventListener("click", function() {
    document.body.classList.toggle('main-nav-open');
})

if(activeSubItem.length !== 0) {
    let parentItem = activeSubItem[0];
    parentItem.closest('.main-nav-sub').previousSibling.classList = "active";
}